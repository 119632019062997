<template>
  <div class="about-region">
    <div class="about-region__wrapper">
      <Row justify="between"
           :wrap="$mobile">
        <p class="about-region__title"
           v-if="$mobile">{{ $t('account.common_info.p_1') }} <br/>
          {{ $t('account.common_info.p_2') }}</p>
        <Column class="about-region__image">
          <div class="about-region__image-holder">
            <img class="about-region__image-image html-insert"
                 :src="$route.params.municipality === 'region'
                       ? $region.image
                       : $city.image"
                 alt="">
          </div>
        </Column>
        <Column class="about-region__general-information">
          <p class="about-region__title"
             v-if="$laptop">{{ $t('account.common_info.p_1') }} <br/>
            {{ $t('account.common_info.p_2') }}</p>
          <p class="about-region__description"
             v-html="$route.params.municipality === 'region'
                     ? $region.description
                     : $city.description"/>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutRegion',
};
</script>
